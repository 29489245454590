import "./App.css";
import LogoHeader from "./components/Header/LogoHeader";
import ContentLine from "./components/Header/ContentLine";
import { useRequest } from "./hooks/useRequest";
import FrontpageStandings from "./components/FrontpageStandings";
import TestPredictionForm from "./components/Predictions/PredictionForm";

function App() {
  const [resp, loading, hasError] = useRequest("/drivers", "GET");

  // return (
  //   // Race active case just for now
  //   <div className="App">
  //     <LogoHeader />
  //     <FrontpageStandings />
  //   </div>
  // );

  // if (hasError) {
  //   return (
  //     <div className="App">
  //       <LogoHeader />
  //       <div className="error-screen">
  //         <h1 className="error-screen-header-text">SORRY</h1>
  //         <p className="error-screen-body-text">
  //           There was an error getting F1 data. Please try again.
  //         </p>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     // Race active case just for now
  //     <div className="App">
  //       <LogoHeader />
  //       <ContentLine />
  //       {resp != null && <TestPredictionForm data={resp} />}
  //     </div>
  //   );
  // }

  return (
    // Race active case just for now
    <div className="App">
      <LogoHeader />
      <ContentLine />
      {/* {resp != null && <TestPredictionForm data={resp} />} */}
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "white" }}>
          Predictions closed - check back soon!
        </h1>
      </div>
    </div>
  );
}

export default App;
