import React, { useState, useEffect } from "react";

export function useRequest(uri, method, opts) {
  const defaultOpts = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'OANFQtauHk8HwoXbJZQMD688mD0P0A7I9QeksrI9'
    }
  }

  const fullOpts = {...defaultOpts, ...opts};
  const url = 'https://3vi746w5ca.execute-api.us-east-1.amazonaws.com/default' + uri;
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetch(url, fullOpts)
    .then((res) => res.json())
    .then((data) => {
      setResponse(data);
      setLoading(false)
      setHasError(false)
    })
    .catch(() => {
      setHasError(true)
      setLoading(false)
    })
  }, [])
  
  return [ response, loading, hasError ];
};